import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

// Partials
import CalendarToolbar from '@/components/partials/CalendarToolbar';
import CalendarMonth from '@/components/partials/CalendarMonth';
import CalendarQuarter from '@/components/partials/CalendarQuarter';
import CalendarWeekOverview from '../partials/CalendarWeekOverview';
import Resources from '@/components/partials/Resources';
import Services from '@/components/partials/Services';
import Times from '@/components/partials/Times';

// Elements
import Spinner from '@/components/elements/Spinner';
import Panel from '@/components/elements/Panel';

import { MessageType } from '@/types';

import { ConfigKeys } from '@/misc/constants';
import {
    Configuration,
    ServicesStateType,
    TimesStateType,
    BookingStateType,
    ServiceType,
} from '@/types';
import { Trans } from '@lingui/macro';
import { ApplicationState } from '@/store';

interface Props {
    booking: BookingStateType;
    configuration: Configuration;
    services: ServicesStateType;
    times: TimesStateType;
    messages: MessageType[];
}

export class TimesServices extends React.Component<Props> {
    render() {
        const { configuration, services, times, messages } = this.props;

        const topOffset = configuration.topOffset || 0;


        if (services.isLoading)
            return (
                <Spinner>
                    <Trans id="loadingTimes"></Trans>
                </Spinner>
            );

        // Make sure that atleast some times are loaded before showing the calendar
        const finishedLoading =
            services.data
                .map((service) => times[service.Id].isLoading)
                .filter((isLoading) => isLoading === false).length > 0;

        // if service has been preselected we should pass it to month view
        const service = services.data.filter(
            (service) => String(service.Id) === String(configuration.selectedService)
        )[0];

        return (
            <div>
                {messages.map((message, index) => (
                    <Alert key={index} color={message.type === 'error' ? 'danger' : 'info'}>
                        {message.messageId === 'API_LIMIT_TIMES' ? (
                            <Trans id="apiLimitTimes" />
                        ) : (
                            <p>{message.value}</p>
                        )}
                    </Alert>
                ))}
                <Panel>
                    <CalendarToolbar />
                    {!finishedLoading && (
                        <Spinner>
                            <Trans id="loadingTimes"></Trans>
                        </Spinner>
                    )}

                    {finishedLoading &&
                        configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_MONTH && (
                            <CalendarMonth service={service} />
                        )}
                    {finishedLoading &&
                        configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_QUARTER && (
                            <CalendarQuarter />
                        )}

                    {finishedLoading &&
                    configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_WEEKLY_OVERVIEW ? (
                        <CalendarWeekOverview />
                    ) : null}
                </Panel>

                <div id="times" style={{ scrollMarginTop: `${topOffset}px`}} >
                    {finishedLoading && configuration.selectedDate && this.renderServices()}
                </div>
          </div>
        );
    }

    renderServices = () => {
        const { services, booking } = this.props;

        return (
            <Services
                services={services.data}
                hideSelect
                append={(service: ServiceType) => (
                    <div>
                        <Resources booking={booking} service={booking.service} hidePreview />
                        <Times service={service} showDate={false} />
                    </div>
                )}
            />
        );
    };
}

const mapStateToProps = ({ configuration, booking, services, times, messages }: ApplicationState) => ({
    configuration: configuration.data,
    booking,
    services,
    times,
    messages,
});

export default compose<React.ComponentType>(connect(mapStateToProps))(TimesServices);
