import {
  createAsyncAction,
  createAction,
  createStandardAction,
  PayloadAction,
} from "typesafe-actions";
import { createAction as reduxCreateAction } from '@reduxjs/toolkit';
import {
  CompanyType,
  PromoCode,
  ResourceInterface,
  ServiceType,
  ResourceTypeType,
} from "@/types";
import qs from "qs";

export const authenticateWithKeyCloack = createAsyncAction(
  "KEYCLOACK_AUTHENTICATE_REQUEST",
  "KEYCLOACK_AUTHENTICATE_SUCCESS",
  "KEYCLOACK_AUTHENTICATE_FAILURE"
)<{ redirectTo: string }, any, any>();

export const fetchCompanyAsync = createAsyncAction(
  "FETCH_COMPANY_REQUEST",
  "FETCH_COMPANY_SUCCESS",
  "FETCH_COMPANY_FAILURE"
)<undefined, CompanyType, Error>();

export const applyPromoCodeAsync = createAsyncAction(
  "APPLY_PROMO_CODE_REQUEST",
  "APPLY_PROMO_CODE_SUCCESS",
  "APPLY_PROMO_CODE_FAILURE"
)<
  {
    RebateCodeSign: string;
  },
  PromoCode,
  Error
>();

export const calculatePriceAsync = createAsyncAction(
  "CALCULATE_PRICE_REQUEST",
  "CALCULATE_PRICE_SUCCESS",
  "CALCULATE_PRICE_FAILURE"
)<undefined, any, Error>();

export const createAccountAsync = createAsyncAction(
  "CREATE_ACCOUNT_REQUEST",
  "CREATE_ACCOUNT_SUCCESS",
  "CREATE_ACCOUNT_FAILURE"
)<undefined, any, Error>();

export const showPromoCodeInput = createAction("SHOW_PROMO_CODE_INPUT");
export const hidePromoCodeInput = createAction("HIDE_PROMO_CODE_INPUT");

export const selectResource =
  createStandardAction("SELECT_RESOURCE")<{
    resource: ResourceInterface | null;
    service: ServiceType | null;
    resourceType: ResourceTypeType;
  }>();

export const KEYCLOACK_AUTHENTICATE_REQUEST = "KEYCLOACK_AUTHENTICATE_REQUEST";
export const KEYCLOACK_AUTHENTICATE_SUCCESS = "KEYCLOACK_AUTHENTICATE_SUCCESS";
export const RESET_APP_STATE = "RESET_APP_STATE";
export const RESET_PARENT_CONFIG = "RESET_PARENT_CONFIG";

export const ADD_LOCALIZE_CATALOG = 'ADD_LOCALIZE_CATALOG';

export const fixLoadingTooLong = createAsyncAction(
  "FIX_LOADING_TOO_LONG_REQUEST",
  "FIX_LOADING_TOO_LONG_SUCCESS",
  "FIX_LOADING_TOO_LONG_FAILURE"
)<void, void, Error>();

export const flushState = reduxCreateAction(
  "persist/flush",
  (data: { selectedServiceId: number }) => {
    return {
      payload: data,
    };
  }
);

export const login = reduxCreateAction("keycloak/login");

export const redirectAfterLogin = reduxCreateAction("keycloak/redirectAfterLogin");

export const logout = reduxCreateAction('keycloak/logout');

export const redirectAfterLogout = reduxCreateAction("keycloak/redirectAfterLogout");

export const rehydrateState = reduxCreateAction('state/rehydrate', (data: string) => {
  return {
    payload: JSON.parse(data)
  }
});

export const reLogin = reduxCreateAction("keycloak/reLogin");