import { createReducer } from 'typesafe-actions';
import { CalculatedPriceResponse } from '@/types';

const initialState = {
    isLoading: false,
    hasError: false,
    error: null,
    data: undefined,
};

interface State {
    isLoading: boolean;
    hasError: boolean;
    error: null | any;
    data?: CalculatedPriceResponse;
}

const reducer = createReducer<State>(initialState, {
    SELECT_SERVICE: () => initialState,
    SELECT_TIME: () => initialState,
    CALCULATE_PRICE_REQUEST: (state, action) => ({
        ...state,
        isLoading: true,
        data: state.data,
        hasError: false,
        error: null,
    }),
    CALCULATE_PRICE_SUCCESS: (state, action) => ({
        ...state,
        isLoading: false,
        data: action.payload,
        hasError: false,
        error: null,
    }),
    CALCULATE_PRICE_FAILURE: (state, action) => ({
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
    }),
});

export default reducer;
