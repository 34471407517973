import { configureStore, TypedStartListening } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";

// @ts-ignore
import { addFormSubmitSagaTo } from "redux-form-submit-saga";
import createRootReducer from "./reducers";
import bmApiService from "@/services/bmApi";
import { authListener } from "./reducers/authenticate";
import { createHashHistory } from "history";
import { configurationListener } from "./reducers/configuration";
import { routerMiddleware } from "connected-react-router";
import googleAnalyticsMiddleware from "@/middlewares/googleAnalytics";
import googleTagManagerMiddleware from "@/middlewares/googleTagManager";
import facebookPixelMiddleware from "@/middlewares/facebookPixel";
import { sagas } from "@/sagas/index";
import { PersistedState } from "./types";
import { createIframeStorage } from "./misc/createIframeStorage";



export const storage = createIframeStorage();

const persistConfig = {
  key: "bm-embed-root",
  storage,
  blacklist: [
    "authenticate",
    "authorise",
    "subscribe",
    bmApiService.reducerPath,
    "ui",
    "form",
    "analytics"
  ],
  timeout: 0,
};

export const history = createHashHistory();



export type LoadEntity = {
  isLoaded: boolean;
  isError: boolean;
  isLoading: boolean;
};

export type authenticateInitialState = LoadEntity & {
  isLoggedIn: boolean;
  error: any;
  state?: PersistedState;
  persist?: {
    rehydrated: boolean,
  }
};

type User = {
  CompanyId?: string;
  Id?: string;
  Firstname?: string;
  Lastname?: string;
  Email?: string;
  WorkerId?: string;
  Phone?: string;
};

export type authoriseInitialState = LoadEntity & {
  error: any;
};

export type UiInitialState = {
  parentScrollTop: number;
}

export const createStoreWithInitialState = (initialState?: any, routerHistory = history) => {
  const rootReducer = createRootReducer(routerHistory);
  const sagaMiddleware = createSagaMiddleware();

  let middlewares: any[] = [
    // createLogger({
    //   // collapsed: true,
    //   // @ts-ignore
    //   predicate: (s, a) =>
    //     a &&
    //     a.type &&
    //     (a.type.includes("keycloak") ||
    //       a.type.includes("state") ||
    //       a.type.includes("authenticate") ||
    //       a.type.includes("authorise") ||
    //       a.type.includes("customer") || 
    //       a.type.includes("persist")),
    //   stateTransformer(state: ApplicationState) {
        
    //     // console.dir(window.location.href);
    //     // @ts-ignore
    //     return {
    //       routerPath: state.router.location.pathname,
    //       'windowPath - hash': window.location.hash,
    //       authenticate: state.authenticate
    //     };
    //   },
    // }),
    sagaMiddleware,
  ];

  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    middlewares = [
      ...middlewares,
      googleTagManagerMiddleware,
      googleAnalyticsMiddleware,
      facebookPixelMiddleware,
    ];
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    devTools: true,
    preloadedState: initialState,
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).prepend(
        routerMiddleware(routerHistory),
        bmApiService.middleware,
        ...middlewares,
        authListener.middleware,
        configurationListener.middleware
      ),
    ],
  });

  let persistor = persistStore(store);

  sagaMiddleware.run(addFormSubmitSagaTo(sagas));

  return { store, persistor };
};

export const { store, persistor } = createStoreWithInitialState();

export type ApplicationDispatch = ReturnType<
  typeof createStoreWithInitialState
>["store"]["dispatch"];

export type ApplicationState = ReturnType<
  ReturnType<typeof createStoreWithInitialState>["store"]["getState"]
>;

export type TypedListener = TypedStartListening<ApplicationState, ApplicationDispatch>;



