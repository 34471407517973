import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import BEMHelper from 'react-bem-helper';
import { BookingStateType, PriceType } from '@/types';

import './Quantities.css';
import { Trans } from '@lingui/macro';
import LocalizedPricePerTime from './LocalizedPricePerTime';

interface Props {
    booking: BookingStateType;
    totalPrice: number;
    appliedDiscounts: any[];
}

const c = new BEMHelper({
    name: 'Quantities',
});

export const Quantities: React.FC<Props> = (props) => {
    const { booking, totalPrice = 0, appliedDiscounts = [] } = props;

    const service = booking.finalService;
    if (!service) return null;

    const prices = service.Prices;
    const hasPrices = prices.length > 0;

    const totalQuantity = booking.quantities
        .map((quantity) => quantity.Quantity)
        .reduce((a, b) => {
            return a + b;
        }, 0);

    const quantities = booking.quantities.filter((quantity) => {
        return quantity.Quantity > 0;
    });

    return (
      <Table size="sm" responsive borderless>
        <thead>
          <tr>
            {hasPrices && (
              <th>
                <Trans id="category"></Trans>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {booking?.priceDetails?.map((priceDetail, index) => {
            return (
              <tr>
                <td>
                  {priceDetail.Quantity} x {priceDetail.Description}
                </td>
              </tr>
            )
          })}
          {hasPrices &&
            appliedDiscounts.map((discount, index) => {
              return (
                <tr key={index} {...c("discountRow")}>
                  <td>
                    {<Trans id="discount"></Trans>} ({discount.RebateCodeValue}
                    {discount.RebateCodeType.Id === 1
                      ? "%"
                      : prices[0].PriceSign}
                    )
                  </td>
                  {totalQuantity > 1 && <td />}
                  <td {...c("discountAmount")}>
                    {discount.RebateAmount.toFixed(2)}
                    {prices[0].PriceSign}
                  </td>
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr {...c("totalRow")}>
            {hasPrices && (
              <td>
                <strong>{<Trans id="totally"></Trans>}</strong>
              </td>
            )}
            {hasPrices && (
              <td colSpan={2}>
                <strong>
                {typeof totalPrice === 'number' ? totalPrice.toFixed(2) : '-'}
                {prices[0].PriceSign}
                </strong>
              </td>
            )}
          </tr>
        </tfoot>
      </Table>
    );
};

const QuantityPrice: React.FC<{
  value: number | string;
  sign: string;
  calculationTypeId: number;
}> = ({ value, sign, calculationTypeId }) => {
  const _value = typeof value === "number" ? value.toFixed(2) : "-";

  return (
    <>
      <LocalizedPricePerTime
        value={_value}
        sign={sign}
        calculationTypeId={calculationTypeId}
      />
    </>
  );
};

export default compose<React.ComponentType>(
    connect(({ booking, prices }: any) => ({
        booking,
        totalPrice: prices.data?.TotalPrice,
        appliedDiscounts: prices.data?.AppliedCodes,
    }))
)(Quantities);
