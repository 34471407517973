import { ApplicationDispatch, ApplicationState, storage } from "@/store";
import { AnyAction, ListenerEffect } from "@reduxjs/toolkit";
import { rehydrateState } from "@/actions";
import keycloak, { keycloakConfig } from "@/keycloak";
import qs from "qs";
import { BACK_URI } from "@/providers/IdentityProvider";
import { PersistedState } from "@/types";
import { persistor } from "@/store";
import { replace } from "connected-react-router";
import { authenticateFail, authenticateSuccess } from ".";
import { clearCustomer } from "../customer";

export const loginEffectDirect: ListenerEffect<
  AnyAction,
  ApplicationState,
  ApplicationDispatch
> = (action, api) => {
  try {
    persistor.flush();
    persistor.pause();
    const location = api.getState().router.location;
    const searchParams = new URLSearchParams(window.location.search);

    const _authenticate = {...api.getState().authenticate};
    delete _authenticate.state;
    const stateToPersist: Partial<PersistedState> = {
      redirectTo: location.pathname,
      authenticate: JSON.stringify(_authenticate)
    };
    storage.setItem(keycloakConfig.clientId, JSON.stringify(stateToPersist));

    keycloak.login({
      redirectUri: `${
        window.location.origin
      }/#/auth?${searchParams.toString()}`,
    });
  } catch (error) {
    console.log(error);
  }
};

export const loginEffectEmbed: ListenerEffect<
  AnyAction,
  ApplicationState,
  ApplicationDispatch
> = async (action, api) => {
  try {
    persistor.flush();
    persistor.pause();

    const location = api.getState().router.location;
    const configuration = api.getState().configuration.data;
    const targetOrigin = configuration?.targetOrigin
      ? configuration?.targetOrigin
      : configuration?._targetOrigin;

    const backUri = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })[BACK_URI]
      ? qs.parse(window.location.search, { ignoreQueryPrefix: true })[BACK_URI]
      : window.location.href;

    const backUriWithState = new URL(backUri as string);

    const redirectUri = keycloak.createLoginUrl({
      redirectUri: backUriWithState.href,
    });

    const _authenticate = {...api.getState().authenticate};
    delete _authenticate.state;
    const stateToPersist: Partial<PersistedState> = {
      redirectTo: location.pathname,
      authenticate: JSON.stringify(_authenticate)
    };
    
    storage.setItem(keycloakConfig.clientId, JSON.stringify(stateToPersist));
    
    window.parent.postMessage({ redirectTo: redirectUri }, targetOrigin || "*");
  } catch (error) {
    api.dispatch(authenticateFail(error));
    console.error(error);
  }
};

export const reLoginEffectEmbed: ListenerEffect<
  AnyAction,
  ApplicationState,
  ApplicationDispatch
> = async (action, api) => {
  try {
    if (
      // State conditions
      api.getState().authenticate.isLoading === false &&
      api.getState().authenticate.isLoggedIn === false &&
      // Action conditions
      rehydrateState.match(action) &&
      action.payload.authenticate &&
      JSON.parse(action.payload.authenticate).isLoading === true &&
      (await api.condition((a, s) =>
        s.router.location.pathname.includes("/services")
      ))
    ) {
      const configuration = api.getState().configuration.data;
      const targetOrigin = configuration?.targetOrigin
        ? configuration?.targetOrigin
        : configuration?._targetOrigin;
      const parsedSearch = qs.parse(window.location.hash.split("?")[1], {
        ignoreQueryPrefix: true,
      }) as {
        code?: string;
      };
      const { code } = parsedSearch;
      if (!code) {
        await keycloak.init({ checkLoginIframe: false });
        keycloak.authenticated = true;
        const redirectTo = api.getState().authenticate.state?.redirectTo!;

        const url = new URL(window.location.origin);
        url.hash = redirectTo as string;
        url.search = window.location.search;

        window.history.pushState({}, "", url.href);

        api.dispatch(replace(redirectTo as string));

        window.parent.postMessage({ login: true }, targetOrigin || "*");
        api.dispatch(authenticateSuccess());
        persistor.flush();
        persistor.persist();
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const reLoginEffectDirect: ListenerEffect<
  AnyAction,
  ApplicationState,
  ApplicationDispatch
> = async (action, api) => {
  try {
    if (
      // State conditions
      api.getState().authenticate.isLoading === false &&
      api.getState().authenticate.isLoggedIn === false &&
      // Action conditions
      rehydrateState.match(action) &&
      action.payload.authenticate &&
      JSON.parse(action.payload.authenticate).isLoading === true &&
      (await api.condition((a, s) =>
        s.router.location.pathname.includes("/services")
      ))
    ) {
      
      api.dispatch(clearCustomer());
    }
  } catch (error) {
    console.log(error);
  }
};