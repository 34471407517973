import { RootState } from './reducers';

export const getServicesToShow = (state: RootState) => {
    const { services, configuration } = state;
    const { serviceIdsToShow }= configuration.data;
    if (Array.isArray(serviceIdsToShow)) {
        return services.data.filter((service) =>
          serviceIdsToShow
            .map((s) => s.toString())
            .includes(service.Id.toString())
        );
    } else if(typeof serviceIdsToShow === 'string') {
        return services.data.filter(s => s.Id.toString() === serviceIdsToShow);
    } else if(typeof serviceIdsToShow === 'number') {
        return services.data.filter(s => s.Id.toString() === serviceIdsToShow.toString());
    } else {
        return services.data;
    }
};
