import React, { useEffect, useState } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router";
import { Provider } from "react-redux";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import qs from "qs";
import _ from "lodash";
import sanitizeHTML from "light-sanitize-html";
import { PersistGate } from 'redux-persist/integration/react'

import { history, storage, store, persistor } from "@/store";

import MainLayout from "@/components/MainLayout";
import NotFound from "@/components/pages/NotFound";
import Services from "@/components/pages/Services";
import Times from "@/components/pages/Times";
import Confirm from "@/components/pages/Confirm";
import Completed from "@/components/pages/Completed";
import PaymentSuccess from "@/components/pages/PaymentSuccess";
import PaymentFailed from "./pages/PaymentFailed";

import { scrollToElement, transformConfiguration } from "@/misc/common";
import readConfigurationProperty from "@/misc/readConfigurationProperty";
import { ConfigKeys, ORIGINAL_CONFIG_STRING_KEY } from "@/misc/constants";
import I18nProvider from "@/providers/I18nProvider";
import { appConfig } from "../config";

import IdentityProvider from "@/providers/IdentityProvider";
import ErrorBoundary from "./elements/ErrorBoundary";
import { addConfiguration } from "@/reducers/configuration";
import AuthLogin from "./AuthLogin";
import AuthLogout from "./AuthLogout";
import { rehydrateState } from "@/actions";
import { keycloakConfig } from "@/keycloak";
import { updateScrollTop } from "@/reducers/ui";
import AddedToQueue from "./pages/AddedToQueue";

const NO_AUTH_PATHS = ["/payment-success", "/payment-failed"];

if (process.env.REACT_APP_ENVIRONMENT === "prod" && appConfig.instrumentationKey && readConfigurationProperty('analytics')) {
  console.log("AI");
  const reactPlugin = new ReactPlugin();

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appConfig.instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
    },
  });

  appInsights.loadAppInsights();
}

let locationChangedTimes = 0;

let handleConfigChange = async () => {
  let locationSearchParsed: any = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  
  let locationHashParsed: any = qs.parse(window.location.hash.split("?")[1], {
    ignoreQueryPrefix: true,
  });
  
  locationSearchParsed = transformConfiguration(locationSearchParsed);
  locationHashParsed = transformConfiguration(locationHashParsed);
  const originalConfig = await storage.getItem(ORIGINAL_CONFIG_STRING_KEY);
  
  if (locationSearchParsed) {
    store.dispatch(addConfiguration(locationSearchParsed));
    if(!originalConfig) {
      storage.setItem(
        ORIGINAL_CONFIG_STRING_KEY,
        qs.stringify(locationSearchParsed)
      );
    }
  } else if (locationHashParsed) {
    store.dispatch(addConfiguration(locationHashParsed));
  }
}

handleConfigChange();

if (window.location !== window.parent.location) {
  window.iFrameResizer = {
    onMessage: function (msg?: { type: string; payload: any } | string) {
      if (
        _.isObject(msg) &&
        msg.type === "INJECT_APP_CSS" &&
        typeof msg.payload === "string"
      ) {
        const style = document.createElement("style");
        style.innerHTML = sanitizeHTML(msg.payload);
        document.body.appendChild(style);
      }

      if (
        _.isObject(msg) &&
        msg.type === "SCROLL" &&
        typeof msg.payload === "number"
      ) {
        store.dispatch(updateScrollTop(msg.payload))
      }

      if (_.isObject(msg) && msg.type === "REHYDRATE_STATE") {
        store.dispatch(rehydrateState(msg.payload));
      }
    },
    // targetOrigin: store.getState().configuration.data.targetOrigin
  };
}

const App = () => {
  const bookLayoutFromStore = store.getState().configuration?.data?.bookLayout;
  const [bookLayoutState, setBookLayoutState] = useState(bookLayoutFromStore);
  const locationSearch = window.location.search;

  let RootComponent = Services;

  // @ts-ignore
  if (bookLayoutState === ConfigKeys.BOOK_LAYOUT_TIME_BASED) {
    // @ts-ignore
    RootComponent = Times;
  }

  useEffect(() => {
    const rehydrate = async () => {
      const state = await storage.getItem(keycloakConfig.clientId);

      if (typeof state === "string") {
        store.dispatch(rehydrateState(state));
      }
    };

    rehydrate();

    if (!readConfigurationProperty("preventAutoscroll", false)) {
      scrollToElement();
    }

    const unlisten = history.listen(() => {
      document.getElementById("paysonContainer")?.remove();

      if (
        locationChangedTimes > 0 &&
        !readConfigurationProperty("preventAutoscroll", false)
      ) {
        scrollToElement();
      }
      locationChangedTimes++;
    });

    const unsubscribe = store.subscribe(() => {
      // we need booking layout to be stateful in order to render proper component
      const bookLayout = store.getState()?.configuration.data.bookLayout;
      if (bookLayout) {
        setBookLayoutState(bookLayout);
      }
    });

    const offset = readConfigurationProperty("topOffset", 0);
    if (window?.bookingAppContainer?.style) {
      window.bookingAppContainer.style.cssText = `scroll-margin-top:${offset}px`;
    }

    return () => {
      unsubscribe();
      unlisten();
    };
  }, []);

  useEffect(() => {
    const checkConfigChange = async () => {
      const originalConfig = await storage.getItem(ORIGINAL_CONFIG_STRING_KEY);
      let locationSearchParsed: any = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      locationSearchParsed = transformConfiguration(locationSearchParsed);
      
      if(locationSearchParsed) {
        delete locationSearchParsed.code;
        delete locationSearchParsed.state;
        delete locationSearchParsed.session_state;
        
        if (originalConfig !== qs.stringify(locationSearchParsed)) {
          persistor.purge();
        }
      }

    };
    checkConfigChange();
  }, [locationSearch]);

  const hashPath = window.location.hash.split("?")[0].replace("#", "");

  const isNoAuthPath = NO_AUTH_PATHS.some((path) => path === hashPath);

  return (
    <Provider store={store as any}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nProvider>
          <ConnectedRouter history={history}>
            {isNoAuthPath ? (
              <Switch>
                <Route path="/payment-success" component={PaymentSuccess} />
                <Route path="/payment-failed" component={PaymentFailed} />
              </Switch>
            ) : (
              <IdentityProvider>
                <ErrorBoundary>
                  <MainLayout>
                    <Switch>
                      <Route exact path="/" component={RootComponent} />
                      <Route
                        exact
                        path="/services/:serviceId/times"
                        component={Times}
                      />
                      <Route
                        path="/services/:serviceId/confirm"
                        component={Confirm}
                      />
                      <Route
                        path="/services/:serviceId/completed"
                        component={Completed}
                      />
                      <Route
                        path="/services/:serviceId/addedtoqueue"
                        component={AddedToQueue}
                      />
                      <Route path="/auth" component={AuthLogin} />
                      <Route path="/logout" component={AuthLogout} />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  </MainLayout>
                </ErrorBoundary>
              </IdentityProvider>
            )}
          </ConnectedRouter>
        </I18nProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
