import React from 'react';

// Partials
import ServicesPartial from '@/components/partials/Services';

export function Services() {
    return <ServicesPartial />;
}

export default Services;
