import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { definitions } from '@/apitypes';
import { ApplicationState } from '@/store';
import keycloak from '@/keycloak';
import readConfigurationProperty from "../misc/readConfigurationProperty";
export type User = definitions['CurrentUserQueryResponse'];


export const apiService = createApi({
    reducerPath: 'bmApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        // @ts-ignore
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            'x-language': readConfigurationProperty('language') || 'sv',
        },
        prepareHeaders(headers, api) {
            const bearerToken = keycloak?.token
            const sessionId = keycloak?.sessionId;

            if (bearerToken && sessionId) {
                headers.set('Authorization', `Bearer ${bearerToken}`);
                headers.set('X-Ss-Id', `${sessionId}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => {
        return {
            getUsers: builder.query<any, Partial<{ IncludeCompanyCustomers: boolean }>>({
                // transformResponse: (res: Paging<Service>) => res.Results.map(s => s.Prices).flat(),
                query: (params = {}) => ({
                    url: '/users',
                    method: 'get',
                    params
                }),
            }),
            
        };
    },
});

export const {
    useGetUsersQuery
} = apiService;

export default apiService;
