import { CompanyType } from '@/types';
import { createReducer } from 'typesafe-actions';


type InitialState = {
    isLoading: boolean
    hasError: boolean
    error: any
    data: CompanyType | null,
}

const initialState: InitialState = {
    isLoading: false,
    hasError: false,
    error: null,
    data: null,
};

const reducer = createReducer(initialState, {
    FETCH_COMPANY_REQUEST: (state, action) => ({
        ...state,
        isLoading: true,
        data: null,
        hasError: false,
        error: null,
    }),
    FETCH_COMPANY_SUCCESS: (state, action) => ({
        ...state,
        isLoading: false,
        data: action.payload,
        hasError: false,
        error: null,
    }),
    FETCH_COMPANY_FAILURE: (state, action) => ({
        ...state,
        isLoading: false,
        data: null,
        hasError: true,
        error: action.payload,
    }),
});

export default reducer;
