import { ApplicationDispatch, ApplicationState, storage } from "@/store";
import { AnyAction, ListenerEffect } from "@reduxjs/toolkit";
import { logout } from "@/actions";
import keycloak, { keycloakConfig } from "@/keycloak";
import qs from "qs";
import { clearSession } from "@/misc/common";
import { clearCustomer } from "../customer";
import { persistor } from "@/store";
import bmApi from "@/services/bmApi";
import { push } from "connected-react-router";

export const logoutEffectDirect: ListenerEffect<
  AnyAction,
  ApplicationState,
  ApplicationDispatch
> = async (action, api) => {
  if(logout.match(action)) {
      
      sessionStorage.removeItem(keycloakConfig.clientId);
      const searchParams = new URLSearchParams(window.location.search);
    
      const searchParsed = qs.parse(window.location.search);
      delete searchParsed.success;
    
      const params = new URLSearchParams(window.location.search);
      params.delete("session_state");
      params.delete("code");
      params.delete("state");
    
      const url = new URL(window.location.href);
      url.search = params.toString();
      url.hash = `#/logout?${searchParams.toString()}`;
      
      api.dispatch(clearCustomer())
      api.dispatch(bmApi.util.resetApiState())
      keycloak.logout();

      if (await api.condition((a, s) => !s.authenticate.isLoggedIn)) {
        window.history.pushState("", "", url.href);
      }
  }

  if(clearCustomer.match(action)) {
    persistor.flush();
    persistor.persist();
  }
};

export const logoutEffectEmbed: ListenerEffect<
  AnyAction,
  ApplicationState,
  ApplicationDispatch
> = async (action, api) => {
  try {
    const searchParams = new URLSearchParams(window.location.search);

    clearSession(keycloak.clientId!, keycloak.refreshToken!).then(() => {
      api.dispatch(clearCustomer());

      const pathname = api.getState().router.location.pathname;
      const _authenticate = {...api.getState().authenticate};
      delete _authenticate.state;
      const payload = JSON.stringify({
        redirectTo: pathname,
        authenticate: _authenticate
      });

      storage.setItem(keycloakConfig.clientId, payload)
      
      api.dispatch(
        push({ pathname: "/logout", search: searchParams.toString() })
      );
      
      // const targetOrigin = api.getState().configuration.data.targetOrigin;
      // window.parent.postMessage({ logout: true }, targetOrigin || "*");
      
    });
  } catch (error) {
    console.error(error);
  }
};
